#about {
    background-color: var(--bg-light);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about {
    background: url("../../assets/about-bg.avif") no-repeat center/cover;
    width: 100%;
    height: 100vh
}

.about .see-more {
    padding-top: 2.5rem;
    font-size: 1.2rem;
}

.about .see-more span {
    cursor: pointer;
    font-size: 1.1rem;
    transform: translateX(0%);
    transition: all 0.4s ease-in-out;
}

.about .see-more:hover {
    transform: translateX(0.5%);
    transition: all 0.4s ease-in-out;

    span {
        transition: all 0.4s ease-in-out;
        padding: 0 0.5rem;
    }

}

.about .headline {
    margin-top: 2rem;
    width: 100% !important;
}

.about-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
}

.about-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.about-img {
    width: 30%;
}

.about-img img {
    background: no-repeat center/cover;
    border-radius: 70px 0px 70px 0px;
    width: 100%;
}

.about-content-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68%;

    border-radius: 0 70px 0 70px;
    background: url("../../assets/about-content-bg.avif") no-repeat center/cover;
}

.about-content {
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 80%;
}

.vig {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.vig p {
    padding-bottom: 4rem;
}

.vig-50 {
    width: 49%;
}

.vig-R {
    border-radius: 70px 0px 70px 0px;
}

.vig-L {
    border-radius: 0px 70px 0px 70px;
}

.about-content .aboutH h2 {
    color: var(--main-color);
    font-size: 2.5rem;
    font-weight: bolder;
    /* font-family: "Fredoka", sans-serif; */
    margin-top: 1%;
    padding-bottom: 2.5rem;
}

.scroll-bar {
    overflow: scroll;
    overflow-x: hidden;
}

.about-content .aboutP p {
    padding: 0 0.5rem;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 145%;
    display: flex;
    justify-content: center;
    height: 200px;
    /* font-family: "Fredoka", sans-serif; */
}

.about-bot {
    margin: 5rem 0;
}

.about-list {
    display: flex;
    justify-content: center;
    gap: 44%;
}

.about-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-icon i {
    padding: 1rem;
    transition: all 0.8s ease;
    transform-style: preserve-3d;
}

.about-icon i:hover {
    transform: rotateY(360deg);
}

.about-icon img {
    width: 62px !important;
    max-width: 70px !important;
    height: auto;
}

.about-icon h4 {
    font-family: "cairo", sans-serif;
    font-weight: 600;
    font-size: 30px;
}


@media only screen and (max-width: 1714px) {

    .about-content .aboutP p {
        font-size: 1.5rem;
        line-height: 140%;
    }

    .about-icon img {
        width: 48px;
    }

    .about-icon h4 {
        font-size: 24px;
    }

    .about-content .aboutH h2 {
        font-size: 2.4rem;
    }
}


@media only screen and (max-width: 1540px) {

    .about-content .aboutP p {
        font-size: 1.2rem;
        line-height: 130%;
    }

    .about-icon img {
        width: 48px;
    }

    .about-icon h4 {
        font-size: 20px;
    }

    .about-content .aboutH h2 {
        padding-bottom: 1rem;
        font-size: 1.8rem;
    }

    .about .see-more {
        padding-top: 1.7rem;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 1366px) {
    .about .see-more {
        padding-top: 1.5rem;
        font-size: 1rem;
    }

    .about-content .aboutH h2 {
        font-size: 1.7rem;
        padding-bottom: 1.4rem;
    }

    .about-content .aboutP p {
        font-size: 1.1rem;
        line-height: 140%;
    }

    .about-icon img {
        width: 48px;
    }

    .about-icon h4 {
        font-size: 24px;
    }

    .about-list {
        gap: 31%;
    }

    .about-icon img {
        width: 50px !important;
        max-width: 55px !important;
        height: auto;
    }

}

@media only screen and (max-width: 1250px) {
    .about-content .aboutH h2 {
        font-size: 1.4rem;
        padding-bottom: 1.2rem;
    }

    .about-content .aboutP p {
        height: 160px;
        font-size: 1rem;
        line-height: 140%;
    }

    .about-list {
        gap: 20%;
    }

    .about-icon img {
        width: 40px;
    }

    .about-icon h4 {
        font-size: 20px;
    }

    .about .see-more {
        padding-top: 1.5rem;
        font-size: 0.9rem;
    }

}

@media only screen and (max-width: 1150px) {
    .about .see-more {
        padding-top: 1.5rem;
        font-size: 0.8rem;
    }

    .about-content .aboutH h2 {
        font-size: 1.4rem;
        padding-bottom: 1.1rem;
    }

    .about-content .aboutP p {
        height: 170px;
        font-size: 0.9rem;
        line-height: 140%;
    }

    .about-icon img {
        width: 48px;
    }

    .about-icon h4 {
        font-size: 24px;
    }

    .about-list {
        gap: 25%;
    }

    .about-icon i {
        padding: 1rem;
    }

    .about-icon img {
        width: 57px !important;
        max-width: 60px !important;
        height: auto;
    }

}

@media only screen and (max-width: 1090px) {
    .about-content .aboutH h2 {
        font-size: 1.1rem;
        padding-bottom: 0.9rem;
    }

    .about-content .aboutP p {
        height: 145px;
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 130%;
    }

    .about-list {
        gap: 20%;
    }

    .about-icon img {
        width: 40px;
    }

    .about-icon h4 {
        font-size: 18px;
    }

    .about .see-more {
        padding-top: 0.8rem;
        font-size: 0.8rem;
    }

}

@media only screen and (max-width: 900px) {
    .about-top {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .about-content .aboutH h2 {
        font-size: 1.2rem;
        padding-bottom: 1.5rem;
    }

    .about-content .aboutP p {
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 140%;
    }

    .about-img img {
        object-fit: cover;
        height: 300px;
    }

    .about-img {
        margin-bottom: 0.5rem;
        width: 69%;
    }

    .about-list {
        gap: 20%;
    }

    .about-icon img {
        width: 40px;
    }

    .about-icon h4 {
        font-size: 18px;
    }

    .about-content-bg {
        height: 320px;
    }

}

@media only screen and (max-width: 770px) {
    .about-icon img {
        width: 47px !important;
        max-width: 50px !important;
        height: auto;
    }
}

@media only screen and (max-width: 540px) {

    .about-bot {
        margin: 2rem 0;
    }

    .about .see-more {
        padding-top: 1rem;
        font-size: 0.7rem;
    }

    .about-content .aboutH h2 {
        font-size: 1.4rem;
        padding: 0 0 0.5rem 0;
    }

    .about-content-bg {
        width: 100%;
        height: 320px;
    }

    .about-content .aboutP p {
        height: 170px;
        font-size: 0.9rem;
        line-height: 140%;
    }

    .about-img {
        width: 100%;
    }


    .about-icon img {
        width: 48px;
    }

    .about-list {

        gap: 22%;
    }

    .about-icon h4 {
        letter-spacing: 0px !important;
        padding-top: 0.3rem;
        font-size: 10px;
    }

    .about-icon i {
        padding: 4px;
    }

    .about-icon img {
        width: 27px !important;
        max-width: 30px !important;
        height: auto;
    }

    .scroll-barr {
        overflow: scroll;
        overflow-x: hidden;
    }

}

@media only screen and (max-width: 390px) {

    .about-content-bg {
        width: 100%;
        height: 320px;
    }


    .about-img {
        width: 100%;
    }

    .about-list {
        gap: 12%;
    }

    .about-icon h4 {
        padding-top: 0.3rem;
        font-size: 10px;
    }
}
#footer {
    background-color: #21282F;
    background: url("../../assets/footer-bg.png") no-repeat center/cover;
    background-position: top;
    height: 100%;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem 0;
}

.footer .column {
    margin: 3rem 0 0;
    display: inline-block;
    width: 25%;
    vertical-align: top;
    padding: 0 15px;
    box-sizing: border-box;
}

.column a {
    cursor: pointer;
}

iframe {
    width: 100%;
    height: 260px;
    padding-right: 20px;
}


#footer .bord {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    border: none;
    color: #fff;
}

footer a:hover {
    color: var(--main-color);
}

footer a {
    font-family: "Fredoka", sans-serif;
    display: block;
}


.under-border {
    width: 60%;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--main-color);
}

#footer .bord p {
    font-family: "Fredoka", sans-serif;
    font-size: 12px;
}

#footer .bord i {
    font-family: "Fredoka", sans-serif;
    padding: 0 10px;
    font-size: 18px;
}


#footer li {
    margin-bottom: 15px;
}

.footer .C {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Fbord {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--main-color);
    border-radius: 10px;
    padding: 40px;
}

.Fbord img {
    width: 100px;
    margin-top: 0 !important;
}

/* Optional: You can style each column individually */
.footer .column h3 {
    font-family: "Fredoka", sans-serif;
    color: var(--main-color);
    font-size: 16px;
    display: block;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.footer .column p {
    font-size: 14px;
    font-family: "Fredoka", sans-serif;
}

.footer .column img {
    max-width: 90%;
    height: auto;
}

.footer-end {
    font-size: auto;
    padding: 1% 0;
    background-color: #12171cad;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-end h3 {
    font-family: "Cairo", sans-serif;
}

.footer-end span {
    padding: 0 4px;
    color: var(--main-color);
}

.mobile {
    display: flex;
    justify-content: space-around;
    width: 50%;
}

/* Media queries for responsiveness */

@media screen and (max-width: 1024px) {

    .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile {
        width: 100%;
    }

    .footer .column {
        width: 70%;
        padding-bottom: 10px;
    }

    .footer .column img {
        margin-top: 20px;
    }

    footer .Fmap {
        width: 100% !important;
    }


}

@media screen and (max-width: 800px) {
    footer .Fmap {
        position: absolute;
    }

    footer iframe {
        display: none;
    }

    .footer .column img {
        max-width: 70%;
        height: auto;
    }


}


@media screen and (max-width: 576px) {
    .footer {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    #footer .bord p {
        font-size: 8px;
    }

    .footer .Fbord {
        display: none;
    }

}

@media screen and (max-width: 500px) {
    .footer {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    #footer .column a {
        font-size: 14px;
    }

}

@media screen and (max-width: 375px) {
    .footer .Fbord p {
        font-size: 8px;
        padding-bottom: 2px;
    }



}
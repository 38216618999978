#contact {
    height: 100%;
    width: 100%;
    padding: 3rem 0 0 0;
    background: url("../../assets/serv-bg.avif") no-repeat center/cover;
    width: 100%;
    height: 100%
}


.contact-container {
    margin: 0rem 8%;
    padding-bottom: 4rem;
    display: flex;
}

.conatct-top {
    width: 65%;
    margin-top: 2.3%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.cont-left {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 10px;
}

.Sicon-container {
    width: 70%;
    display: inline-flex;
    gap: 20px;
}


#contact .bord {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--main-color);
    font-family: "Cairo", sans-serif;
    font-size: 18px;
    border: 1px solid var(--main-color);
    border-radius: 5px 25px 5px 25px;
    margin-bottom: 2rem;
    padding: 14px 10px;
}

#contact .bord p {
    padding: 0px 10px;
    font-family: "Cairo", sans-serif;
}

#contact .bord i {
    margin: 2px 15px 2px 15px;
    display: block;
    font-size: 20px;
}

.Sicon i {
    display: inline-flex;
    align-items: center;
    color: var(--main-color);
    font-size: 26px;
    border: 1px solid var(--main-color);
    border-radius: 5px 14px 5px 14px;
    margin: 0.75rem 0;
    padding: 10px 10px;
    transition: all 0.3s linear;
}

.Sicon i:hover {
    background-color: var(--main-color);
    color: var(--sec-color);
    box-shadow: rgb(102, 252, 241) 0 0 1rem;
}


.bot-container {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bot-container-bg {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;
    background: url("../../assets/input-bg.avif") no-repeat center/cover;
    border-radius: 30px 5px 30px 5px;
}

.cont-input {
    width: 100%;
    margin: 16px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input {
    width: 100%;
}

.top-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-col input {
    margin: 10px 20px !important;
    padding: 16px !important;
    background-color: var(--sec-color-80);
    width: 100%;
    color: #fff;
    border-radius: 5px;
    border-radius: 25px 5px 25px 5px;
    border: 1px solid transparent;
}

.top-col textarea {
    margin: 10px 20px !important;
    padding: 4px !important;
    background-color: var(--sec-color-80);
    width: 100%;
    height: 18vh;
    color: #fff !important;
    border-radius: 25px 5px 25px 5px;
    border: 1px solid transparent;
}

.emailForm input:focus {
    outline: none;
}

.emailForm textarea:focus {
    outline: none;
}

input:focus,
textarea:focus,
select:focus {
    border: 2px solid #00000000;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

input::placeholder {
    padding: 0 10px;
    font-weight: 650;
    font-family: "Cairo", sans-serif;
}

textarea::placeholder {
    font-family: "Cairo", sans-serif;
    padding: 0 10px;
    font-weight: 650;
}

.msgH {
    color: var(--main-color);
    font-weight: bold;

    font-family: "Fredoka", sans-serif;
    font-size: 24px;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.send-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 25px 5px 25px 5px;
    padding: 15px 0;
    background-color: var(--main-color);
    color: var(--sec-color);
    margin: 0.6rem 0;
    transition: all 0.3s linear;
}

.send-btn:hover {
    background: var(--sec-color-op);
    border: 1px solid var(--main-color);
    color: var(--main-color);
    box-shadow: rgb(102, 252, 241) 0 0 1rem;
}




@media only screen and (max-width: 1525px) {
    .conatct-top {
        width: 55%;

    }

    #contact .bord {
        font-size: 16px;
        margin-bottom: 2rem;

    }

    .bot-container {
        width: 45%;
    }
}

@media only screen and (max-width: 1050px) {
    .conatct-top {
        width: 45%;

    }

    #contact .bord {
        font-size: 14px;
        margin-bottom: 1.5rem;

    }

    .bot-container {
        width: 55%;
    }
}

@media only screen and (max-width: 500px) {

    .contact-container {
        margin: 0 3%;
    }

    .contact-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .conatct-top {
        width: 100%;

    }

    #contact .bord {
        font-size: 14px;
        margin-bottom: 1.5rem;

    }

    .bot-container {
        width: 100%;
    }

    .bot-container-bg {
        width: 100%;
    }


}
@import url('https://fonts.googleapis.com/css2?family=Lexend+Giga:wght@100..900&family=Noto+Kufi+Arabic:wght@100..900&family=Noto+Sans+Arabic:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Giga:wght@100..900&family=Nanum+Gothic&family=Noto+Kufi+Arabic:wght@100..900&family=Noto+Sans+Arabic:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Lexend+Giga:wght@100..900&family=Nanum+Gothic&family=Noto+Kufi+Arabic:wght@100..900&family=Noto+Sans+Arabic:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Fredoka:wght@300..700&family=Lexend+Giga:wght@100..900&family=Nanum+Gothic&family=Noto+Kufi+Arabic:wght@100..900&family=Noto+Sans+Arabic:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --main-color: #66FCF1;
  --main-color-op: #66fcf23a;
  --sec-color: #202833;
  --bg-light: #44556B;
  --sec-color-op: #20283346;
  --sec-color-80: #202833a4;
  --nav-menu-color: #202833ec;
  --text-color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cairo", sans-serif;
  /* font-family: "Lexend Giga", sans-serif; */
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-padding-top: 9rem;

}



::-webkit-scrollbar {
  width: 0.4vw;
}

::-webkit-scrollbar:hover {
  width: 0.7vw;
}


::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 100vw;
  border: 0.25em solid rgba(0, 0, 0, 0.082);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-color);
}

::-webkit-scrollbar-track {
  background: var(--sec-color);
  margin: 14px 0 14px 0;
}


body {
  background-color: var(--sec-color);
  color: var(--text-color);
  scroll-behavior: smooth !important;
}

#main {
  overflow: hidden;
}

section .headline h1 {
  /* font-family: "Fredoka", sans-serif; */
  font-size: 28px;
  font-weight: 400;
  padding: 10px;
  color: var(--main-color);
  position: relative;
  z-index: 1;
}

section .headline h1::before {
  content: "";
  display: block;
  width: 38%;
  height: 2px;
  background-color: var(--main-color);
  left: 50px;
  top: 50%;
  position: absolute;
  z-index: -2;
}

section .headline h1::after {
  content: "";
  display: block;
  width: 38%;
  height: 2px;
  background-color: var(--main-color);
  right: 50px;
  top: 50%;
  position: absolute;
  z-index: -2;
}

.coreV .headline h1::after {
  width: 35%;
}

.coreV .headline h1::before {
  width: 35%;
}

section .headline {
  text-align: center;
}

@media screen and (max-width: 1150px) {
  section .headline h1 {
    /* font-family: "Fredoka", sans-serif; */
    font-size: 28px;
    font-weight: 400;
    padding: 10px;
    color: var(--main-color);
    position: relative;
    z-index: 1;
  }

  section .headline h1::before {
    width: 35%;

  }

  section .headline h1::after {
    width: 35%;
  }
}

@media screen and (max-width: 900px) {
  section .headline h1 {
    /* font-family: "Fredoka", sans-serif; */
    font-size: 24px;
    font-weight: 400;
    padding: 10px;
    color: var(--main-color);
    position: relative;
    z-index: 1;
  }

  section .headline h1::before {
    width: 30%;

  }

  section .headline h1::after {
    width: 30%;
  }

  .coreV .headline h1::after {
    width: 30%;
  }

  .coreV .headline h1::before {
    width: 30%;
  }
}

@media screen and (max-width: 618px) {
  section .headline h1 {
    /* font-family: "Fredoka", sans-serif; */
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    color: var(--main-color);
    position: relative;
    z-index: 1;
  }

  .coreV .headline h1::after {
    width: 24%;
  }

  .coreV .headline h1::before {
    width: 24%;
  }

}

@media screen and (max-width: 450px) {
  section .headline h1 {
    /* font-family: "Fredoka", sans-serif; */
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    color: var(--main-color);
    position: relative;
    z-index: 1;
  }

  section .headline h1::before {
    width: 18%;

  }

  section .headline h1::after {
    width: 18%;
  }

  .coreV .headline h1::after {
    width: 18%;
  }

  .coreV .headline h1::before {
    width: 18%;
  }

}
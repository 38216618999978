.serv {
    display: flex;
    align-items: center;
    justify-content: center;
}

#serv {
    padding-top: 3rem;
    background: url("../../assets/serv-bg.avif") no-repeat center/cover;
    width: 100%;
    height: 100%
}


.serv .container {
    padding: 50px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.parent {
    height: 470px;
    width: 400px;
    padding: 20px;
    perspective: 1000px;
}

.card {
    background-color: #202833;
    padding-top: 150px;
    transform-style: preserve-3d;
    background-position: center center;
    width: 100%;
    transition: all 0.5s ease-in-out;
    border-radius: 0px 70px 0px 70px;

}

.card-1 {
    background-image: url('../../assets/card-1.webp');
}

.card-2 {
    background-image: url('../../assets/card-2.webp');
}

.card-3 {
    background-image: url('../../assets/card-3.webp');
}

.card-4 {
    background-image: url('../../assets/card-4.webp');
}

.card-5 {
    background-image: url('../../assets/card-5.webp');
}

.card-6 {
    background-image: url('../../assets/card-6.webp');
}

.card:hover {
    background-position: 80% 20%;
    transform: rotate3d(0.5, 1, 0, 30deg);
}

.card-title {
    /* font-family: "Fredoka", sans-serif; */
    color: var(--main-color);
    font-size: 24px;
    font-weight: 600;
    transition: all 0.5s ease-in-out;
    transform: translate3d(0px, 0px, 20px);
    text-transform: capitalize;
}

.card-title br {
    border-bottom: 1px solid var();
}

.card-title:hover {
    transform: translate3d(0px, 0px, 50px);
}

.card-content {
    /* font-family: "Fredoka", sans-serif; */
    overflow: hidden;
    height: 60.7%;
    width: 95%;
    padding: 10px 0;
    font-size: 14px;
    color: rgb(255, 255, 255);
    transition: all 0.5s ease-in-out;
    transform: translate3d(0px, 0px, 20px);
}

.card-content:hover {
    transform: translate3d(0px, 0px, 50px);
}

.see-more {
    display: block;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #66FCF1;
    padding: 12px 0 12px 5px;
    transition: all 0.5s ease-in-out;
    transform: translate3d(0px, 0px, 20px);
}

.see-more:hover {
    color: #54cec6 !important;
    transform: translate3d(0px, 0px, 50px);
}

.content-box {
    width: 100%;
    height: 240px;
    /* background: rgba(0, 0, 0, 0.301); */
    background: #20283365;
    border-radius: 5px 70px 5px 70px;
    /* box-shadow: rgba(122, 98, 0, 1) 0px 20px 50px -25px, rgba(87, 93, 50, 0.356) 0px 30px 60px -12px, rgba(0, 0, 0, 0.185) 0px 18px 36px -18px; */
    transition: all .5s ease-in-out;
    padding: 30px 25px 25px 25px;
    transform-style: preserve-3d;
}

.content-box:hover {
    box-shadow: rgb(102, 252, 241) 0px 20px 70px -20px, rgb(32, 40, 51) 0px 30px 60px -12px, rgba(0, 0, 0, 0.385) 0px 18px 36px -18px;
}
 .projects-wrapper {
     background-color: var(--bg-light);
 }

 .project {
     background: url('../../assets/about-bg.avif') no-repeat center/cover;
 }

 .projects-container {
     display: flex;
     align-items: center;
     flex-direction: column;
     width: 100%;
     height: 100%;
     overflow: hidden;
 }

 .project-section {
     width: 100vw;
     height: 100vh;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     opacity: 1;
     padding: 2rem;
 }

 .project .about-content {
     justify-content: center;
     align-items: center;
     text-align: center;
 }

 @media screen and (max-width: 800px) {}

 .project-section {
     width: 100vw;
     height: 100%;
 }
#header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../assets/homeBG.png") no-repeat center/cover;
    width: 100%;
    height: 100vh
}

.sticky-i {
    position: fixed;
    bottom: -6%;
    right: 2%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999999;
}

.sticky-i a {
    display: block;
    text-align: center;
    padding: 14px;
    margin-bottom: 15px;
    transition: all 0.4s linear;
    color: var(--sec-color);
    font-size: 24px;
    border-radius: 8% 35% 8% 35%;
}

.S-btn {
    background: var(--main-color-op);
}

.S-btn:hover {
    background: var(--main-color);
}


.sticky-i .hidden {
    cursor: default;
    opacity: 0 !important;
}

.homeContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.typing-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeContent img {
    width: 67px;
    padding: 2rem 0;
    margin: 0 1rem;
}

.homeContent .typing-text {
    color: var(--main-color);
    font-size: 45px;
    font-weight: bolder;
    font-family: "Cairo", sans-serif;
    text-shadow: 1px 2px 7px var(--main-color-op);
    padding-bottom: 1.5rem;
}

.homeContent h4 {
    font-size: 34px;

}

.homeContent span {
    font-family: "Nanum Gothic", sans-serif;
    color: var(--main-color);
}


@media screen and (max-width: 800px) {
    .homeContent h4 {
        font-size: 1.5rem;
    }

    .homeContent .typing-text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .homeContent h4 {
        font-size: 1rem;
    }

}

@media screen and (max-width: 550px) {

    .sticky-i a {
        font-size: 18px;
        padding: 12px;
    }
}


@media screen and (max-width: 480px) {

    .homeContent h4 {
        letter-spacing: 0.2rem;
    }

    .homeContent img {
        width: 47px;
        padding: 2rem 0;
        margin: 0 1rem;
    }

    .homeContent {
        padding-bottom: 4rem;
    }


}

@media screen and (max-width: 391px) {
    .homeContent h4 {
        letter-spacing: 0.2rem;
        font-size: 0.7rem;
    }

    .homeContent .typing-text {
        letter-spacing: 0.2rem;
        font-size: 1.2rem;
    }

    .sticky-i a {
        font-size: 16px;
        padding: 10px;
    }
}
.navbar {
    width: 100%;
    position: fixed;
    color: #fff;
    background-color: var(--sec-color-op);
    padding-top: 0.3rem;
    padding-bottom: 0 !important;
    transition: all ease-in-out 0.4s;
    z-index: 999;
}

.nav-container {
    width: 95%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    align-items: center;
}

.logo img {
    margin-bottom: 0;
    padding-bottom: 0;
    width: 50px !important;
    height: auto;

}

.logo {
    padding-right: 0rem;
    padding-left: 5rem;
    padding-bottom: 0;
}

.topnav {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
}

.topnav a {
    font-family: "Cairo", sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 14px 40px 24px 40px;
    margin-bottom: 0;
    text-decoration: none;
    font-size: 16px;

}

.lng-btn {
    position: absolute;
    content: '';
    top: 40%;
    left: 5%;
    text-align: center;
    color: var(--main-color);
}

.lng-btn button {
    font-family: "Cairo", sans-serif;
    padding: 3px 6px 3px 6px;
    margin-right: 4px;
    font-weight: 600;
    text-decoration: none;
    font-size: 10px;
}


.lng-btn button.selected {
    background-color: var(--main-color);
    border-radius: 25% 0 25% 0;
    color: var(--sec-color);
}

.lng-btn button:hover {
    background-color: var(--main-color);
    border-radius: 18% 0 18% 0;
    color: var(--sec-color);

}

.logo:hover {
    background-color: #22222200 !important;
}

.topnav a {
    position: relative;
    border: 1px transparent;
    margin: 16px 4px 0 4px;
    cursor: pointer;
}

.menu-btn {
    display: none;
}


.topnav a::before {
    margin-right: 1rem;
    border-bottom: 2px solid var(--main-color);
    width: 80% !important;
    content: "";
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
    z-index: -1;
}

.topnav a:hover::before {
    transform: scaleX(1);
    transform-origin: right;
}


.Nactive {
    color: var(--sec-color);
    background-color: var(--main-color);
    border-radius: 15px 0;
    transition: color 0.8s, background-color 0.8s;
}

.Hactive {
    color: var(--main-color);
    border-bottom: 1px solid var(--main-color);
}


.navbar-bg {
    background-color: var(--sec-color-op);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: var(--main-color) !important;
    transition: all ease-in-out 0.4s;
}

.navbar-bg a:hover {
    background-color: var(--main-color);
    color: var(--sec-color);
    border-radius: 20px 0;
    transition: linear 0.7s;
}


@media screen and (max-width: 650px) {
    .topnav i {
        display: block;
    }

}

@media screen and (max-width: 1200px) {
    .topnav a {
        font-size: 17px;
    }


}


@media screen and (max-width: 1070px) {
    .topnav a {
        padding: 14px 20px;
        font-size: 15px;
    }


    .logo {
        padding-left: 5.5rem;
    }

    .navbar-bg {
        border-bottom: 5px solid var(--color-primary);
    }

}


@media screen and (max-width: 790px) {
    .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .topnav a {
        text-align: left;
        font-size: 0.8rem !important;
        padding: 1px 30px;
    }

    .logo {
        padding: 0.6rem;

    }

    .topnav {
        transform: translateX(-150%);
        opacity: 0;
        transition: all 0.4s ease-in-out;
        position: absolute;
        top: 3.1rem;
        top: -1rem;
        left: -0.8rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 200px !important;
        height: 110vh !important;
        background-color: var(--nav-menu-color);
        padding-bottom: 0.6rem;
        padding-top: 4rem;

        a {
            display: none;

        }
    }

    .topnav a::before {
        width: 90% !important;

    }

    .topnav a {
        padding-top: 0.8rem;
        margin-top: 2rem;
    }

    .show {
        transition: all 0.4s ease-in-out;
        transform: translateX(0%);
        opacity: 1;


        a {
            display: block;
        }

    }

    .lang-buttons {
        display: none;
    }

    .menu-btn {
        top: 1.6rem !important;
        right: -7.5rem !important;
    }

    .menu-button {
        margin-right: 1rem;
    }

    .menu-btn {
        display: inline;
        color: var(--main-color);

        i {
            font-size: 1.6rem;
        }

    }

    .navbar-bg {
        border-bottom: 4px solid var(--color-primary);
    }

}


@media screen and (max-height: 650px) {
    .topnav a::before {
        background: transparent !important;
    }

    .topnav a.touch:hover::before {
        transform-origin: bottom !important;
    }


    .navbar-bg a.touch:hover {
        background-color: var(--main-color) !important;
        color: #fff !important;
    }


    .navbar-bg a:hover {
        background-color: transparent !important;
        color: var(--color-primary) !important;
    }

    .Hactive {
        color: var(--color-primary) !important;
        background: none !important;
    }
}


@media screen and (max-width: 650px) {
    .topnav a {
        padding: 14px 20px;

    }

    .lng-btn {
        top: 26%;
        left: 5%;
    }

    .topnav a::before {
        background: transparent;
    }

    .topnav a.touch:hover::before {
        transform: scaley(1);
        transform-origin: bottom;
    }

    .navbar-bg a.touch:hover {
        background-color: var(--color-primary);
        color: #fff;
    }

    .navbar-bg a:hover {
        background-color: transparent;
        color: var(--color-primary);
    }

    .Hactive {
        color: var(--color-primary) !important;
        background: none;
    }

    .logo {
        padding-left: 3rem;
    }

    .navbar-bg {
        border-bottom: 3px solid var(--color-primary);
    }

}


@media screen and (max-width: 660px) {
    .topnav a {
        padding: 14px 16px;


    }

    .logo {
        padding-left: 2.2rem;
    }

    .navbar-bg {
        border-bottom: 3px solid var(--color-primary);
    }

    .lng-btn button {
        font-size: 10px;
    }

    .carousel-indicators [data-bs-target] {
        width: 20px;
        height: 2px;
    }

}

@media screen and (max-width: 500px) {

    .logo {
        padding-left: 3rem;
    }

}

@media screen and (max-width: 475px) {
    .topnav a {
        padding: 12px 10px;

    }


    .logo img {
        width: 30px !important;
        padding-bottom: 0.2rem;
        height: auto;
    }

    .logo {
        padding-left: 1.6rem;
    }


    .lng-btn {
        top: 21%;
        left: 5%;
    }

    .lng-btn button {
        font-size: 8px;

    }

}


@media screen and (max-width: 390px) {


    .topnav a {
        padding: 14px 6px;
    }

    .carousel-indicators [data-bs-target] {
        width: 14px;
        height: 2px;
    }
}

@media screen and (max-width: 290px) {
    .topnav a {
        padding: 14px 7px;

    }


    .logo {
        padding-left: 1rem;
    }

    .lng-btn button {
        font-size: 6px;
    }

}